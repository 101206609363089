import { Button, Input } from '@/components'
import CreateAccountModal from '@/components/Modals/Accounts/CreateAccountsModal'
import ProgressBar from '@/components/ProgressBar'
import notFound from '@/assets/not-found.svg'

import { useAuthContext, useCurrencyContext } from '@/context'
import { useGetDedicatedBankAccounts } from '@/services/accounts/queries'
import { DedicatedBankAccountType } from '@/types/accounts'
import { formatAmountNoSymbol } from '@/utils/money'

import { useMemo, useState } from 'react'
import AccountCard from '../components/AccountCard'
import { filterAccountBtn, filterType } from '../utils'
import { motion } from 'framer-motion'
import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { ADMIN_ACCOUNTS_PERMISSIONS } from '@/utils/permisson-helper'
import NotAuthorized from '@/layout/NotAuthorized'
import { isStagingEnvironment, prodEnvironment } from '@/utils/utils'
import CurrencyFilter from '@/components/CurrencyFilter'
import { useSearchParams } from 'react-router-dom'
import { AccountingEmptyState } from '@/assets/assets.export'
import { BankAccountConnectionTypeProps } from '@/services/accounts/types'

export default function BizpendAccounts() {
    const [createAccountModal, setCreateAccountModal] = useState(false)
    const { selectedAccount } = useAuthContext()
    const [searchInput, setSearchInput] = useState('')
    const permission = useHasAccessPermisson(ADMIN_ACCOUNTS_PERMISSIONS)
    const [searchParams] = useSearchParams()

    const [setAccountType, searchParamDetails] = useAddQueryParamsToURL<{ accountType: keyof typeof filterType }>()
    const subRoute = searchParamDetails.get('accountType') ?? filterType.ngn
    const { data, isLoading, isRefetching } = useGetDedicatedBankAccounts({
        enabled: permission,
        queryParams: {
            entity_id: selectedAccount?.org?.id as string,
            connection_type: BankAccountConnectionTypeProps.CREATE,
        },
    })
    const bankAccounts = useMemo(() => data?.accounts ?? ([] as DedicatedBankAccountType[]), [data])

    const { selectedCurrencyInfo } = useCurrencyContext()
    const currency = selectedCurrencyInfo(searchParams.get('currency') ?? '')

    const handleSearchAccounts = (text: string) => {
        if (!text) {
            return bankAccounts
        } else {
            return bankAccounts.filter(account => {
                const isNickNameMatch = account?.nickname?.toLowerCase().includes(text)
                const isAccountNumberMatch = account?.account_number?.toString().includes(text)
                const isNameMatch = account?.account_name?.toLowerCase().includes(text)
                const isBankNameMatch = account?.bank_name?.toLowerCase().includes(text)
                return isNameMatch || isAccountNumberMatch || isNickNameMatch || isBankNameMatch
            })
        }
    }

    if (!permission) return <NotAuthorized />
    if (isLoading) return <ProgressBar />
    return (
        <section className='my-5'>
            <div className='flex flex-col md:flex-row md:items-center justify-between gap-5'>
                <div>
                    <h2 className='text-lg font-semibold'>Bizpend Accounts</h2>
                    <p className='text-sm text-gray7'>These are accounts created by Bizpend</p>
                </div>
                <Button onClick={() => setCreateAccountModal(true)}>Create new account</Button>
            </div>

            <div className='mt-5 bg-white rounded-xl p-3 md:p-5 space-y-2'>
                {isStagingEnvironment() && <CurrencyFilter />}
                <div className=' flex lg:items-center justify-between flex-col lg:flex-row gap-2 lg:gap-0 w-full'>
                    <div className='flex max-w-3xl justify-between flex-1'>
                        <div className='first:border-none first:pl-0 border-l pl-7 border-gray5 w-full '>
                            <h3 className='text-3xl text-gray7 font-bold'>
                                {currency.symbol}
                                {/* to be changed later */}
                                {currency.name === 'Naira'
                                    ? formatAmountNoSymbol(data?.totalCreatedAccountsBalance)
                                    : '0.00'}
                            </h3>
                        </div>
                    </div>
                    <div>
                        <Input
                            onChange={e => setSearchInput(e.target.value)}
                            placeholder='search accounts'
                            className='h-10 border-gray6 drop-shadow-sm'
                        />
                    </div>
                </div>
            </div>
            {prodEnvironment() && (
                <ul className='flex items-center gap-4 my-8 bg-white p-3 w-fit rounded-lg'>
                    {filterAccountBtn.map(btnDetails => {
                        if (btnDetails?.hidden) return
                        return (
                            <li
                                className={`relative ${btnDetails.id === subRoute ? 'text-brand ' : 'bg-transparent text-[#5E5E5E]'} text-sm rounded-md p-2 px-3`}
                                onClick={() => setAccountType('accountType', btnDetails.id)}
                            >
                                <button className='flex gap-2'>{btnDetails.desc} </button>
                                {btnDetails.id === subRoute && (
                                    <motion.div
                                        layoutId='accounting-filter'
                                        className={
                                            'bg-brand/10 rounded  text-sm  w-full left-[1px] top-0  bottom-0 absolute'
                                        }
                                    />
                                )}
                            </li>
                        )
                    })}
                </ul>
            )}
            <div
                className={`grid pb-16  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 gap-y-10 mt-10 ${isRefetching ? 'opacity-60' : ''}`}
            >
                {handleSearchAccounts(searchInput.toLowerCase())?.map(account => (
                    <AccountCard bankAccounts={bankAccounts} account={account} />
                ))}
                {handleSearchAccounts(searchInput.toLowerCase())?.length === 0 && searchInput.length > 0 && (
                    <div
                        className={`'h-[450px] col-span-6 flex flex-col text-center justify-center items-center pb-10 ${
                            isRefetching ? 'opacity-30' : ''
                        }`}
                    >
                        <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                        <span className='text-xl font-bold text-[#5E5E5E] mt-3'>Sorry! No result found :(</span>
                        <span className='text-[#202020] text-sm lg:w-[45%] mx-auto mt-3 font-medium'>
                            Oops! It seems we couldn't find any matching results for your search. Please try again with
                            different keywords or refine your search fiter for better results.
                        </span>
                    </div>
                )}
                {handleSearchAccounts(searchInput.toLowerCase())?.length === 0 && searchInput.length === 0 && (
                    <div className='col-span-6 p-8  rounded-xl flex flex-col text-center justify-center items-center'>
                        <AccountingEmptyState className='mx-auto' />
                        <span className='text-xl font-medium text-[#5E5E5E] mt-3'>No accounts created yet</span>
                        <span className='text-gray7 mt-2 text-sm w-[90%] lg:w-1/2 mx-auto'>
                            Create your first account to get started!
                        </span>
                    </div>
                )}
            </div>
            {createAccountModal && <CreateAccountModal closeModal={() => setCreateAccountModal(false)} />}
        </section>
    )
}
